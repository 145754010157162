<template>
  <a-modal
    :visible="visibleWelcomeFreemiumModal"
    :footer="null"
    @cancel="closeModal"
    class="welcome-to-freemium-modal"
  >
    <div class="welcome-to-freemium-container">
      <div class="welcome-to-freemium-container__header">
        <img src="@/assets/images/logo@2x.webp" alt="logo" />
      </div>
      <div class="welcome-to-freemium-container__body">
        <h2 class="welcome-to-freemium-container__body--title">
          Welcome to <span>Glocal!</span>
        </h2>
        <p class="welcome-to-freemium-container__body--description">
          Experience the world's first <span>superintelligence</span> environment,
          <br />
          Powered by Professionals & AI
        </p>
        <h2 class="welcome-to-freemium-container__body--sub-title">
          Your 2-week free subscription begins today.
        </h2>
      </div>
      <div class="welcome-to-freemium-container__footer">
        <h3 class="welcome-to-freemium-container__footer--title">
          Subscribe to Glocal Premium to get full access!
        </h3>
        <div class="welcome-to-freemium-container__footer--package">
          <p class="title">Starting from</p>
          <div class="price">
            <h4 v-if="subscriptionPlan.length > 0">$ {{ subscriptionPlan[0]?.price }}</h4>
            <p>/ Month</p>
          </div>
        </div>
        <button
          @click="viewPackagesModal"
          class="welcome-to-freemium-container__footer--btn"
        >
          View plan options
        </button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    visibleWelcomeFreemiumModal: Boolean,
  },
  computed:{
    ...mapGetters({
      'subscriptionPlan' : 'auth/getSubscriptionPlans'
    })
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    viewPackagesModal() {
      this.$store.commit("auth/setSubscriptionPackagesVisibilty", true);
    },
  },
};
</script>

<style lang="scss">
.welcome-to-freemium-modal {
  width: 60.1rem !important;
  top: 8rem !important;
  .ant-modal-content {
    .ant-modal-close {
      margin: 1.9rem 2rem 0 0 !important;
      .ant-modal-close-x {
        .anticon {
          color: $color-white !important;
        }
      }
    }
    .ant-modal-body {
      .welcome-to-freemium-container {
        border-radius: 1rem;
        &__header {
          background-image: 
          linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)), 
          url("../../../assets/images/Professionals.jpg");
          height: 17.2rem;
          background-repeat: no-repeat;
          background-position: center;
          background-position-y: 44%;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          border-start-end-radius: 1rem;
          border-start-start-radius: 1rem;
          img {
            width: 5.1rem;
            height: auto;
          }
        }
        &__body {
          padding: 3.2rem 2.4rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.219);
          &--title {
            font-family: $font-primary;
            font-size: 2.8rem;
            text-align: center;
            line-height: 3.6rem;
            color: $color-black;
            margin-bottom: 1.8rem;
            span {
              font-family: $font-primary-bold;
            }
          }
          &--description {
            font-family: $font-primary;
            font-size: 2.2rem;
            text-align: center;
            line-height: 2.8rem;
            color: $color-black;
            opacity: 0.6;
            margin-bottom: 2.4rem;
            span {
              color: $color-primary;
              text-transform: uppercase;
              font-family: $font-primary-medium;
            }
          }
          &--sub-title {
            font-family: $font-primary-medium;
            font-size: 1.8rem;
            text-align: center;
            line-height: 2.3rem;
            color: $color-black;
            margin-bottom: 0;
          }
        }
        &__footer {
          padding: 3rem 2.3rem;
          &--title {
            font-family: $font-primary-medium;
            font-size: 2rem;
            text-align: center;
            line-height: 3rem;
            color: $color-black;
            opacity: 1;
            margin-bottom: 0;
          }
          &--package {
            margin: 2rem auto 3.2rem;
            width: fit-content;
            display: block;
            .title {
              font-family: $font-primary;
              font-size: 1.4rem;
              text-align: center;
              line-height: normal;
              color: $color-black;
              margin-bottom: 0;
            }
            .price {
              display: flex;
              align-items: center;
              margin-top: 0.3rem;
              h4 {
                font-family: $font-primary-bold-2;
                font-size: 4rem;
                text-align: center;
                line-height: normal;
                color: $color-primary;
                margin-bottom: 0;
              }
              p {
                font-family: $font-primary-bold;
                font-size: 1.6rem;
                text-align: center;
                line-height: normal;
                color: $color-black;
                margin-bottom: 0;
                margin-left: 0.3rem;
              }
            }
          }
          &--btn {
            background-color: $color-primary;
            font-family: $font-primary-medium;
            font-size: 1.6rem;
            height: 4.8rem;
            width: 100%;
            border: none;
            outline: none;
            color: $color-white;
            cursor: pointer;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
            span {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
